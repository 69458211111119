import axios from 'axios';

const service = axios.create({
    timeout: 40000,
    // baseURL: 'http://10.21.166.230:8003/api' //生产地址
    // baseURL: 'http://10.95.2.103:8003/api' //测试地址

    // == 新
    baseURL: 'https://api-pc.pomoho.com' //生产地址
    // baseURL: 'https://dev.pomoho.com' //测试地址
})

// 请求拦截
service.interceptors.request.use(config => {
    // 处理参数
    return config;
},err => {
    console.log("请求拦截报错内容："+err)
})

// 响应拦截
service.interceptors.response.use(response => {
    // 处理参数
    return response;
},err => {
    console.log("响应拦截报错内容："+err)
})

// 导出请求函数
export function get(url, params){
    return service.get(url, {params})
}

export function post(url, data){
    return service.post(url, data)
}

export function ifProduction(){
    // console.log(service.defaults.baseURL)
    if(service.defaults.baseURL.indexOf("api-pc.pomoho.com")>=0){
        return true
    }else{
        return false
    }
}