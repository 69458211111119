<template>
  <div class="wrap" :style="{marginTop: '-' + $store.state.safetyTop + 'px'}">
    <div
      class="topBox"
      :style="{
        paddingTop: $store.state.safetyTop + 'px',
      }"
    >
      <div class="row r1">
        <!-- <div class="place">南宁市星湖路39号大板二</div> -->
        <div class="cutEditionBtn" @click="cutEditionFn">切换身份</div>
      </div>
      <div class="row r2">
        <div class="ipt">
          <div class="icon"></div>
          <input type="text" placeholder="找劳务工人" disabled/>
        </div>
        <div class="messageBtn" @click="toOutsideChain('bridge:/baseWorker/openMessageView/pageInit')">
          <!-- <div class="num">99</div> -->
        </div>
      </div>
    </div>
    <div class="ban">
      <swiper
        :pagination="pagination"
        :modules="modules"
        :loop="true"
        class="banSwiper"
      >
        <swiper-slide :style="`height:calc(5.8rem + ${$store.state.safetyTop}px)`" class="it2" @click="loginJump(1, 'bridge:/baseWorker/openLaowuMiniView/pageInit')"></swiper-slide>
        <swiper-slide :style="`height:calc(5.8rem + ${$store.state.safetyTop}px)`" @click="loginJump(1, 'bridge:/baseWorker/openLaowuMiniView/pageInit')">
          <div class="txt txt1">
            <h3>
              加入华建劳务人才库<br />
              让老板直接找到你
            </h3>
            <p>华建通与吉工家联合打造2024招聘专场</p>
          </div>
        </swiper-slide>
        <!-- <swiper-slide>
          <div class="txt txt1">
            <h3>加入华建劳务人才库<br>
            让老板直接找到你</h3>
            <p>华建通与吉工家联合打造2024招聘专场</p>
          </div>
        </swiper-slide> -->
      </swiper>
    </div>
    <main class="home">
      <div class="quick">
        <swiper
          :pagination="pagination2"
          :modules="modules2"
          :slides-per-view="5"
          :slidesPerGroup="5"
          :space-between="centerSpace"
          :grid="grid2"
          class="quickSwiper"
        >
          <swiper-slide>
            <div
              class="a"
              @click="loginJump(1, 'bridge:/baseWorker/openMobileAtteView/pageInit')"
            >
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon1.png" alt="" />
              </div>
              <div class="dd">考勤打卡</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="a"
              @click="loginJump(1, 'bridge:/baseWorker/openEntraceInviteView/pageInit')"
            >
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon2.png" alt="" />
              </div>
              <div class="dd">入场邀请</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="a"
              @click="loginJump(1, 'bridge:/baseWorker/openLaowuMiniView/pageInit')"
            >
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon3.png" alt="" />
              </div>
              <div class="dd">招工找活</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="a"
              @click="loginJump(1, 'bridge:/baseWorker/openSafeTrainView/pageInit')"
            >
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon4.png" alt="" />
              </div>
              <div class="dd">安全培训</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="a"
              @click="loginJump(1, 'bridge:/baseWorker/openSalaryCardView/pageInit')
              "
            >
              <div class="note" v-if="typeKa">{{typeKa}}</div>
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon5.png" alt="" />
              </div>
              <div class="dd">工资绑卡</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="a"
              @click="loginJump(1, 'bridge:/baseWorker/openMyContractView/pageInit')
              "
            >
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon6.png" alt="" />
              </div>
              <div class="dd">电子合同</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="a"
              @click="loginJump(1, 'bridge:/baseWorker/openAtteView/pageInit')
              "
            >
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon7.png" alt="" />
              </div>
              <div class="dd">考勤查询</div>
            </div>
          </swiper-slide>
          <!-- <swiper-slide>
            <div
              class="a"
              @click="loginJump(1, 
                  'bridge:/webview/open/pageInit?url=http://10.96.133.254:8081/h5Mall/pages/healthServer/index'
                )
              "
            >
              <div class="dt">
                <img src="~@/assets/images/home/zoologyIconFw.png" alt="" />
              </div>
              <div class="dd">体检服务</div>
            </div>
          </swiper-slide> -->
          <swiper-slide>
            <div class="a" 
            @click="loginJump(1, 'bridge:/webview/open/pageInit?url=https://huajiantong.com/h5Mall/pages/passport/authlogin?pageName=healthServer')"
            >
              <div class="dt">
                <img src="~@/assets/images/home/zoologyIconFw.png" alt="" />
              </div>
              <div class="dd">体检服务</div>
            </div>
          </swiper-slide>
          <!-- <swiper-slide>
            <div class="a" @click="toSTZQ">
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon8.png" alt="" />
              </div>
              <div class="dd">生态专区</div>
            </div>
          </swiper-slide> -->
          <swiper-slide>
            <div
              class="a"
              @click="loginJump(1, 'bridge:/baseWorker/openWagesCheckView/pageInit')
              "
            >
              <div class="note" v-if="monthPayroll">{{monthPayroll}}</div>
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon9.png" alt="" />
              </div>
              <div class="dd">工资查询</div>
            </div>
          </swiper-slide>
          <!-- <swiper-slide>
            <div
              class="a"
              @click="loginJump(1, 'bridge:/baseWorker/openWorkmateView/pageInit')
              "
            >
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon10.png" alt="" />
              </div>
              <div class="dd">工友圈子</div>
            </div>
          </swiper-slide> -->
          <swiper-slide>
            <div class="a" @click="loginJump(1, 'bridge:/baseWorker/openMyLogTeamView/pageInit')">
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon11.png" alt="" />
              </div>
              <div class="dd">班组记工</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="a"
              @click="loginJump(1, 'bridge:/baseWorker/openMyOnTeamView/pageInit')
              "
            >
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon12.png" alt="" />
              </div>
              <div class="dd">班组登记</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="a"
              @click="loginJump(1, 
                  'bridge:/baseWorker/openTeamPayrollView/pageInit'
                )
              "
            >
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon13.png" alt="" />
              </div>
              <div class="dd">班组工资</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="a" @click="loginJump(0, '/personalExcellentScore')">
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon14.png" alt="" />
              </div>
              <div class="dd">华建优分</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="a" @click="seeAmovie">
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon15.png" alt="" />
              </div>
              <div class="dd">一起追剧</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="a"
              @click="loginJump(1, 
                  'bridge:/baseWorker/openMyComplaintView/pageInit'
                )
              "
            >
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon16.png" alt="" />
              </div>
              <div class="dd">我的投诉</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="a"
              @click="loginJump(1, 
                  'bridge:/baseWorker/openWorkerCommitmentView/pageInit'
                )
              "
            >
              <div class="dt">
                <img src="~@/assets/images/home/quickIcon17.png" alt="" />
              </div>
              <div class="dd">进退承诺</div>
            </div>
          </swiper-slide>
         
        </swiper>
      </div>
      <div class="recommend">
        <div class="box b1" @click="comingSoon">
          <div class="dt">
            <h4>
              蓝领入职体检<br />
              套餐
            </h4>
            <p>在线预约</p>
            <p>电子报告</p>
            <p>营养早餐</p>
            <p>报告解读</p>
          </div>
          <div class="dd">
            <h4>华建通平台价</h4>
            <div class="price">
              <div class="i">¥</div>
              <div class="num">188</div>
              <div class="c">/起</div>
            </div>
          </div>
        </div>
        <div class="box b2" @click="comingSoon">
          <h4>达林伟尔工地头盔</h4>
          <p>自带护目镜ABSI工地专用材...</p>
          <div class="pic">
            <img src="~@/assets/images/home/quickGoods2.png" alt="" />
          </div>
          <div class="d"><em>详情</em></div>
        </div>
        <div class="box b3" @click="comingSoon">
          <h4>
            定制品牌<br />
            工作服
          </h4>
          <div class="pic">
            <img src="~@/assets/images/home/quickGoods3.png" alt="" />
          </div>
        </div>
        <div class="box b4" @click="comingSoon">
          <h4>
            时尚防水<br />
            护士鞋
          </h4>
          <div class="pic">
            <img src="~@/assets/images/home/quickGoods4.png" alt="" />
          </div>
        </div>
      </div>
      <div class="ecology">
        <h2 class="hTil">生态专区</h2>
        <div class="ul">
          <div @click="toSTZQ" class="li">
            <div class="dt">
              <img src="~@/assets/images/home/ecologyIcon1.png" alt="" />
            </div>
            <div class="dd">
              <h4>体检健康</h4>
              <p>为广大工友提供质优价廉的体检服务体验</p>
            </div>
          </div>
          <div @click="toSTZQ" class="li">
            <div class="dt">
              <img src="~@/assets/images/home/ecologyIcon2.png" alt="" />
            </div>
            <div class="dd">
              <h4>保险保障</h4>
              <p>安全险、健康险、养老保险让广大工友...</p>
            </div>
          </div>
          <div @click="toSTZQ" class="li">
            <div class="dt">
              <img src="~@/assets/images/home/ecologyIcon3.png" alt="" />
            </div>
            <div class="dd">
              <h4>技能学校</h4>
              <p>学历提升、技能提升资格证书保障工友...</p>
            </div>
          </div>
          <div @click="toSTZQ" class="li">
            <div class="dt">
              <img src="~@/assets/images/home/ecologyIcon4.png" alt="" />
            </div>
            <div class="dd">
              <h4>金融服务</h4>
              <p>你借他还，生活不难为工友提供低息生...</p>
            </div>
          </div>
        </div>
      </div>
      <div class="hotJobs">
        <div class="hTil">热门岗位</div>
        <div class="moreBtn" @click="loginJump(1, 'bridge:/baseWorker/openLaowuMiniView/pageInit')">查看更多</div>
        <div class="ul">
          <div class="li" @click="loginJump(1, 'bridge:/baseWorker/openLaowuMiniView/pageInit')">
            <div class="dt">
              <h4>抹灰工人每天240元</h4>
              <p>龙桥贝艾尼厂内招板房现金20元</p>
              <div class="labels">
                <div class="e">板房拆塔</div>
                <div class="i">南宁同城</div>
              </div>
              <div class="time">2024/03/16</div>
            </div>
            <div class="dd">
              <div class="le">
                <div class="via">
                  <img src="~@/assets/images/home/dynamicIt1.jpg" alt="" />
                </div>
                专业美缝
              </div>
              <div class="contactBtn">立即沟通</div>
            </div>
          </div>
          <div class="li" @click="loginJump(1, 'bridge:/baseWorker/openLaowuMiniView/pageInit')">
            <div class="dt">
              <h4>抹灰工人每天240元</h4>
              <p>龙桥贝艾尼厂内招板房现金20元</p>
              <div class="labels">
                <div class="e">板房拆塔</div>
                <div class="i">南宁同城</div>
              </div>
              <div class="time">2024/03/16</div>
            </div>
            <div class="dd">
              <div class="le">
                <div class="via">
                  <img src="~@/assets/images/home/dynamicIt1.jpg" alt="" />
                </div>
                专业美缝
              </div>
              <div class="contactBtn">立即沟通</div>
            </div>
          </div>
          <div class="li" @click="loginJump(1, 'bridge:/baseWorker/openLaowuMiniView/pageInit')">
            <div class="dt">
              <h4>抹灰工人每天240元</h4>
              <p>龙桥贝艾尼厂内招板房现金20元</p>
              <div class="labels">
                <div class="e">板房拆塔</div>
                <div class="i">南宁同城</div>
              </div>
              <div class="time">2024/03/16</div>
            </div>
            <div class="dd">
              <div class="le">
                <div class="via">
                  <img src="~@/assets/images/home/dynamicIt1.jpg" alt="" />
                </div>
                专业美缝
              </div>
              <div class="contactBtn">立即沟通</div>
            </div>
          </div>
        </div>
      </div>
      <div class="newMessage" v-if="false">
        <div class="hTil">圈子最新动态</div>
        <div class="ul">
          <div class="li" @click="loginJump(1, 'bridge:/baseWorker/openWorkmateView/pageInit')">
            <div class="dt">
              <div class="tilWr">
                <div class="via">
                  <img src="~@/assets/images/home/dynamicIt1.jpg" alt="" />
                </div>
                <div class="ri">
                  <h4>小徐&nbsp;&nbsp;专业刮天花水泥油&nbsp;腻子</h4>
                  <div class="time">58 分钟前</div>
                </div>
              </div>
              <div class="pBox">
                <p>
                  因为天花是要批粗灰的。因为鼓花容易出现空鼓脱落，所以用水泥油代提！因为没有批粗灰或刮水泥油的天花很滑而且不平整；腻子刮上去的寿命时间很短
                </p>
              </div>
            </div>
            <div class="dd">
              <div class="labels">
                <div class="i">南宁同城</div>
              </div>
              <div class="operate">
                <div
                  :class="['btn', 'praise', { on: newPraiseList.includes(1) }]"
                  @click="newPraiseToggle(1)"
                >
                  99+
                </div>
                <div class="btn discuss">26</div>
              </div>
            </div>
          </div>
          <div class="li" @click="loginJump(1, 'bridge:/baseWorker/openWorkmateView/pageInit')">
            <div class="dt">
              <div class="tilWr">
                <div class="via">
                  <img src="~@/assets/images/home/dynamicIt2.jpg" alt="" />
                </div>
                <div class="ri">
                  <h4>专业室内刷漆面</h4>
                  <div class="time">10 小时前</div>
                </div>
              </div>
              <div class="pBox">
                <p>
                  在涂刷乳胶漆之前，需要对水泥墙面的平整度、坚实度等进行仔细检查，尤其要对水泥墙面是否存在空鼓情况进行重点检查。
                </p>
              </div>
            </div>
            <div class="dd">
              <div class="labels">
                <div class="i">南宁同城</div>
              </div>
              <div class="operate">
                <div
                  :class="['btn', 'praise', { on: newPraiseList.includes(2) }]"
                  @click="newPraiseToggle(2)"
                >
                  85
                </div>
                <div class="discuss btn">26</div>
              </div>
            </div>
          </div>
          <div class="li" @click="loginJump(1, 'bridge:/baseWorker/openWorkmateView/pageInit')">
            <div class="dt">
              <div class="tilWr">
                <div class="via">
                  <img src="~@/assets/images/home/dynamicIt3.jpg" alt="" />
                </div>
                <div class="ri">
                  <h4>老王20年专业美缝</h4>
                  <div class="time">02-16</div>
                </div>
              </div>
              <div class="pBox">
                <p>
                  清理施缝槽。在施工前，需要确保施缝槽绝对干净，无灰尘和杂物。可以使用吸尘器清理缝隙内部，同时保持瓷砖表层干净干燥。注意胶枪使用和施工顺序。美缝施工中，要注意胶枪的用法和施工顺序，直线打法是常见的瓷砖美缝线...
                </p>
              </div>
            </div>
            <div class="dd">
              <div class="labels">
                <div class="i">南宁同城</div>
              </div>
              <div class="operate">
                <div
                  :class="['btn', 'praise', { on: newPraiseList.includes(3) }]"
                  @click="newPraiseToggle(3)"
                >
                  85
                </div>
                <div class="discuss btn">26</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="videoList">
        <div class="hTil">一起追剧</div>
        <div class="moreBtn" @click="seeAmovie">查看更多</div>
        <div class="ul">
          <div class="li" @click="seeAmovie">
            <div class="dt">
              <img src="~@/assets/images/home/videoPicNew1.jpg" alt="" />
              <div class="btn"></div>
            </div>
            <div class="dd">
              <h4>天命小相尊</h4>
            </div>
          </div>
          <div class="li" @click="seeAmovie">
            <div class="dt">
              <img src="~@/assets/images/home/videoPicNew2.jpg" alt="" />
              <div class="btn"></div>
            </div>
            <div class="dd">
              <h4>我能接到未来电话</h4>
            </div>
          </div>
          <div class="li" @click="seeAmovie">
            <div class="dt">
              <img src="~@/assets/images/home/videoPicNew3.jpg" alt="" />
              <div class="btn"></div>
            </div>
            <div class="dd">
              <h4>佳偶天成</h4>
            </div>
          </div>
          <div class="li" @click="seeAmovie">
            <div class="dt">
              <img src="~@/assets/images/home/videoPicNew4.jpg" alt="" />
              <div class="btn"></div>
            </div>
            <div class="dd">
              <h4>狂飙之至尊神瞳</h4>
            </div>
          </div>
          <div class="li" @click="seeAmovie">
            <div class="dt">
              <img src="~@/assets/images/home/videoPicNew5.jpg" alt="" />
              <div class="btn"></div>
            </div>
            <div class="dd">
              <h4>冷情傅少我好疼</h4>
            </div>
          </div>
          <div class="li" @click="seeAmovie">
            <div class="dt">
              <img src="~@/assets/images/home/videoPicNew6.jpg" alt="" />
              <div class="btn"></div>
            </div>
            <div class="dd">
              <h4>北境</h4>
            </div>
          </div>
        </div>
      </div>
    </main>
    <van-dialog v-model:show="show" show-cancel-button class="videoHint" @confirm="confirmToVdo">
      <div class="cnt" slot>
        <div class="til">短剧内容由“天津一起追剧文<br/>化传媒有限公司”提供</div>
        <div class="p">短剧真好看，充值需理性</div>
      </div>
    </van-dialog>
  </div>
</template>
<script setup>
import { Pagination, Grid } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import { showDialog } from 'vant';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/grid";

import { ref, onMounted } from "vue";
import { useRouter } from 'vue-router';
const router = useRouter();

const swiperAct = ref(0);
const modules = [Pagination];
const pagination = {
  clickable: true,
};
const leftSpace = ref(0);
const centerSpace = ref(0);
const modules2 = [Pagination, Grid];
const pagination2 = {
  clickable: true,
};
const grid2 = {
  fill: "column",
  rows: 2,
};
const show = ref(false);
const newPraiseList = ref([2]);
const registerState = ref(false); //用户是否登录，本地测试用true，上线用false;
const prestoreId = ref(-1);
const prestoreUrl = ref('');
const showPop = ref(false);
const typeKa = ref('未绑定');
const monthPayroll = ref('5月未发');

// 切换到企业端
const cutEditionFn = () => {
  window.location.href = "bridge:/base/changeAppSystem/pageInit?type=2";
};

const newPraiseToggle = (num) => {
  let isExist = newPraiseList.value.includes(num);
  if (isExist) {
    newPraiseList.value = newPraiseList.value.filter((n) => n != num);
  } else {
    newPraiseList.value.push(num);
  }
};

// 跳转外链
const toOutsideChain = (url) => {
  if (!url) return false;
  window.location.href = url;
};

// 一起看剧弹窗显示
const seeAmovie = () => {
  prestoreId.value = -1
  prestoreUrl.value = ""
  showPop.value = true;
  window.location.href = "bridge:/base/getAppUserLogin/pageInit?callback=callbackLoginEvent";
};

// 敬请期待
const comingSoon = () => {
  showDialog({
    title: '即将开放，敬请期待'
  }).then(() => {
    // on close
  });
}

// 一起看剧确认
const confirmToVdo = () => {
  let url = encodeURIComponent("pages/Tabbar/Tabbar3?from=3")
  window.location.href = `bridge:/native/openMiniApp/pageInit?url=${url}&appId=gh_57a6136de544&pubAppId=wx392ef33f3e75f4aa`;
}

// 跳转生态专区
const toSTZQ = () => {
  window.location.href = "bridge:/baseWorker/switchTabView/pageInit?tab=1";
}

// 业务按钮跳转
const loginJump = (num, url) => {
  prestoreId.value = num
  prestoreUrl.value = url
  window.location.href = "bridge:/base/getAppUserLogin/pageInit?callback=callbackLoginEvent";
}
const swiperRef = ref(null);
const initSwiper = () => {
  // 确保Swiper实例存在且DOM已更新
  if (swiperRef.value) {
    new Swiper(swiperRef.value);
  }
};
// 获取工人绑卡的卡类型及当月发薪状态
function callBackMyWorkBankSalaryState(myWorkBank,myWorkSalary){
  typeKa.value = myWorkBank
  monthPayroll.value = myWorkSalary
}
onMounted(function () {
  window.callBackMyWorkBankSalaryState = callBackMyWorkBankSalaryState;
  // 获取工人绑卡的卡类型及当月发薪状态
  window.location.href="bridge:/baseWorker/getMyWorkBankSalaryState/pageInit"
  initSwiper();
  // 登录状态
  window.callbackLoginEvent = (isLogin) => {
    // isLogin 1 已登录 0 未登录
    if (isLogin == '1') {
      if(prestoreId.value == 1){
        window.location.href = prestoreUrl.value
      }else if(prestoreId.value == 0){
        router.push(prestoreUrl.value)
      }
      if(showPop.value){
        show.value = true;
        showPop.value = false;
      }
      prestoreId.value = -1
      prestoreUrl.value = ""
    }else {
      prestoreId.value = -1
      prestoreUrl.value = ""
      showPop.value = false;
      // *****  调起登录页面的协议
      window.location.href = "bridge:/base/openLoginView/pageInit?callback=callbackLoginEvent";
    }
  }

  let wdWid = window.innerWidth;
  let rem = wdWid / 7.5;
  leftSpace.value = rem * 0.3;
  centerSpace.value = rem * 0.4;
});

</script>
<style scoped lang="scss">
.wrap {
  position: relative;
  padding-top: 0;
}
.topBox {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0 0.3rem;
  z-index: 30;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .place {
      padding: 0 0.25rem 0 0.27rem;
      font-size: 0.26rem;
      color: #fff;
      height: 0.7rem;
      line-height: 0.7rem;
      max-width: 5.2rem;
      background: url(~@/assets/images/iconLocation.png) no-repeat left center /
          0.16rem,url(~@/assets/images/iconDown.png) no-repeat right center / 0.12rem;
    }
    .cutEditionBtn {
      margin-left: auto;
      padding-left: 0.3rem;
      font-size: 0.26rem;
      height: 0.6rem;
      line-height: 0.6rem;
      margin-top: 0.05rem;
      color: #fff;
      background: url(~@/assets/images/iconCut.png) no-repeat left center /
        0.22rem;
    }
    .ipt {
      position: relative;
      width: 6.1rem;
      &:before {
        position: absolute;
        left: 0.32rem;
        top: 0.27rem;
        content: "";
        display: block;
        width: 0.26rem;
        height: 0.26rem;
        background: url(~@/assets/images/iconSeek.png) no-repeat center;
        background-size: contain;
      }
      input {
        border: 0 none;
        width: 100%;
        height: 0.8rem;
        line-height: 0.6rem;
        font-size: 0.24rem;
        color: #333;
        padding: 0.1rem 0.3rem 0.1rem 0.75rem;
        background: #edf1f1;
        border-radius: 0.8rem;
      }
    }
    .messageBtn {
      position: absolute;
      width: 0.6rem;
      height: 0.6rem;
      right: 0.4rem;
      background: url(~@/assets/images/iconMessage.png) no-repeat center;
      background-size: 0.46rem;
      .num {
        position: absolute;
        right: -0.12rem;
        top: 0.03rem;
        font-size: 0.22rem;
        color: #fff;
        width: 0.38rem;
        height: 0.26rem;
        line-height: 0.26rem;
        text-align: center;
        border-radius: 0.08rem;
        background: linear-gradient(to right, #fb7d22, #d75b1a);
      }
    }
  }
  .r1 {
    margin-bottom: 0.24rem;
  }
}
.ban {
  position: relative;
  .swiper-slide {
    position: relative;
    height: 5.8rem;
    background: #9eb3b6 url(~@/assets/images/home/ban1.jpg) no-repeat bottom center;
    background-size: 100%;
    &.it2 {
      background-image: url(~@/assets/images/home/ban2.png);
      background-color: #3c7ae1;
    }
    .txt {
      position: absolute;
      left: 0;
      bottom: 0.6rem;
      height: 2.8rem;
      width: 100%;
      padding: 0.6rem 0.3rem 0;
      h3 {
        font-size: 0.42rem;
        color: #1e3538;
        line-height: 0.5rem;
        font-weight: 600;
        margin-bottom: 0.24rem;
      }
      p {
        font-size: 0.2rem;
        color: #1e3538;
        line-height: 0.28rem;
      }
    }
  }
  :deep(.swiper) {
    .swiper-pagination {
      height: 0.06rem;
      line-height: 0.06rem;
      bottom: 0.34rem;
      width: auto;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 0.06rem;
      background: rgba(255, 255, 255, 0.5);
      font-size: 0;
      .swiper-pagination-bullet {
        vertical-align: top;
        margin: 0;
        width: 0.44rem;
        height: 100%;
        border-radius: 0.44rem;
        opacity: 0;
        transition: all 0.3s;
        background: #fff;
      }
      .swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }
}
.home {
  background: #f3f4f6;
  padding: 0.3rem;
  .hTil {
    font-size: 0.4rem;
    color: #43465a;
    line-height: 0.5rem;
    font-weight: 600;
    margin-bottom: 0.35rem;
  }

  .labels {
    display: flex;
    .i {
      height: 0.34rem;
      line-height: 0.34rem;
      padding: 0 0.1rem;
      font-size: 0.2rem;
      color: #007df8;
      background: #e5f2fe;
      margin-right: 0.1rem;
    }
    .e {
      font-size: 0.2rem;
      color: #2caf5a;
      height: 0.34rem;
      line-height: 0.32rem;
      padding: 0 0.1rem;
      margin-right: 0.1rem;
      border: 0.01rem solid #2caf5a;
      border-radius: 0.04rem;
    }
  }
}

.quick {
  margin-bottom: 0.5rem;
  :deep(.swiper) {
    height: 4rem;
    margin: 0 -0.3rem;
    padding: 0.2rem 0.3rem;
    .swiper-wrapper{
      justify-content: space-between;
    }
    .swiper-slide {
      position: relative;
      width: 1.06rem;
      height: 1.54rem;
      padding: 0.18rem 0 0;
      .a{
        width: 1.06rem;
        height: 1.54rem;
      }
      .dt {
        width: 0.88rem;
        height: 0.88rem;
        margin: 0 auto 0.15rem;
        img {
          border-radius: 0.3rem;
          box-shadow: 0 0.06rem 0.5rem rgba(0, 0, 0, 0.06);
        }
      }
      .dd {
        font-size: 0.26rem;
        color: #43465a;
        line-height: 0.32rem;
        text-align: center;
        white-space: nowrap;
      }
      .note {
        position: absolute;
        left: 0;
        right: 0;
        top: -0.08rem;
        font-size: 0.2rem;
        margin: 0 auto;
        color: #fff;
        width: 0.8rem;
        text-align: center;
        height: 0.42rem;
        line-height: 0.42rem;
        border-radius: 0.12rem;
        background: #007df8;
      }
    }
    .swiper-pagination {
      height: 0.06rem;
      line-height: 0.06rem;
      bottom: 0;
      width: auto;
      left: 50%;
      transform: translateX(-50%);
      background: #d8d9db;
      font-size: 0;
      border-radius: 0.06rem;
      .swiper-pagination-bullet {
        vertical-align: top;
        margin: 0;
        width: 0.44rem;
        height: 100%;
        border-radius: 0.44rem;
        opacity: 0;
        transition: all 0.3s;
        background: #007df8;
      }
      .swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }
}
.recommend {
  overflow: hidden;
  margin-bottom: 0.8rem;
  .box {
    position: relative;
    float: left;
    background: #fff;
    overflow: hidden;
    border-radius: 0.2rem;
    img {
      max-width: 100%;
      max-height: 100%;
    }
    h4 {
      font-size: 0.28rem;
      color: #434659;
      line-height: 0.36rem;
      font-weight: 600;
    }
    p {
      font-size: 0.22rem;
      color: #848ba1;
      line-height: 0.32rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .b1 {
    width: 3.35rem;
    height: 4.72rem;
    margin-right: 0.2rem;
    .dt {
      height: 3.66rem;
      padding: 0.3rem 0.24rem;
      background: url(~@/assets/images/home/quickPic1.jpg) no-repeat center;
      background-size: cover;
      h4 {
        margin-bottom: 0.16rem;
      }
      line-height: 0.36rem;
    }
    .dd {
      padding: 0.2rem 0.3rem;
      background: #fff;
      h4 {
        font-size: 0.26rem;
        color: #333333;
        line-height: 0.3rem;
        font-weight: 600;
        margin-bottom: 0.06rem;
      }
      .price {
        display: flex;
        font-size: 0.2rem;
        color: #e62a2b;
        line-height: 0.3rem;
        align-items: flex-end;
        vertical-align: bottom;
        .i {
          font-size: 0.24rem;
          font-family: "DIN-Bold";
        }
        .num {
          font-size: 0.34rem;
          font-family: "DIN-Bold";
        }
      }
    }
  }
  .b2 {
    width: 3.35rem;
    height: 2.26rem;
    padding: 0.3rem 0.24rem;
    margin-bottom: 0.2rem;
    background: url(~@/assets/images/home/recommendBg2.jpg) no-repeat center;
    background-size: cover;
    .pic {
      position: absolute;
      width: 1.2rem;
      height: 1rem;
      right: 0.2rem;
      bottom: 0.2rem;
    }
    .d {
      margin-top: 0.4rem;
      width: 0.9rem;
      height: 0.44rem;
      border-radius: 0.44rem;
      line-height: 0.42rem;
      text-align: center;
      border: 0.01rem solid #4f7ba3;
      font-size: 0.2rem;
      color: #4f7ba3;
      em {
        padding-right: 0.13rem;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAALCAYAAABcUvyWAAAAsUlEQVQYlU3PMWoCURSF4c9kAeIC0oRsII2Qzsoija8JaJgNmCIbcMrZgWVSBBxImuADsbGxsrdwCzZWKQJJERh54oT54Vb/vedwDSazj8FkNq6qSnMu8INpyMuRBkk8YY63kJf3tWuls5CXHSxwi34sss1JJEJeXiEiLYV/cZbX2GCfOprcoY11M+oB73jBc13ewxIrjGKR/V5u/266+MQOw1hk3/UfrzjgMRbZ1ykXR21BReCFt60uAAAAAElFTkSuQmCC)
          no-repeat right center;
        background-size: 0.06rem;
      }
    }
  }
  .b3,
  .b4 {
    width: 1.57rem;
    height: 2.26rem;
    padding: 0.18rem 0.2rem;
    background: url(~@/assets/images/home/recommendBg3.jpg) no-repeat center;
    background-size: cover;
    h4 {
      font-size: 0.24rem;
      line-height: 0.3rem;
      text-align: center;
    }
    .pic {
      margin-top: 0.2rem;
      height: 1.12rem;
      img {
        margin: 0 auto;
      }
    }
  }
  .b4 {
    float: right;
    background-image: url(~@/assets/images/home/recommendBg4.jpg);
  }
}
.ecology {
  margin-bottom: 0.6rem;
  .ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.2rem;
    height: 1.54rem;
    width: 3.35rem;
    margin-bottom: 0.2rem;
    border-radius: 0.2rem;
    background: #fff;
    .dt {
      width: 0.78rem;
    }
    .dd {
      width: 2rem;
      h4 {
        font-size: 0.26rem;
        line-height: 0.36rem;
        color: #3c3f50;
        font-weight: 600;
      }
      p {
        font-size: 0.22rem;
        color: #5b5d6a;
        line-height: 0.3rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}
.newMessage {
  margin-bottom: 0.5rem;
  .li {
    padding: 0.26rem 0.2rem 0.16rem;
    margin-bottom: 0.3rem;
    background: #fff;
    border-radius: 0.2rem;
    box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.03);
    .dt {
      padding-bottom: 0.2rem;
    }
    .tilWr {
      display: flex;
      margin-bottom: 0.2rem;
      .via {
        width: 0.72rem;
        height: 0.72rem;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 0.2rem;
      }
      .ri {
        flex: 1;
        h4 {
          font-size: 0.28rem;
          color: #3c3f50;
          line-height: 0.36rem;
          font-weight: 600;
        }
        .time {
          font-size: 0.22rem;
          color: #5b5d6a;
          line-height: 0.36rem;
        }
      }
    }
    .pBox {
      p {
        font-size: 0.24rem;
        color: #5b5d6a;
        line-height: 0.36rem;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }
    .dd {
      border-top: 0.01rem solid #eee;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 0.1rem;
      .operate {
        display: flex;
      }
      .btn {
        height: 0.6rem;
        line-height: 0.6rem;
        font-size: 0.24rem;
        color: #3c3f50;
        padding-left: 0.42rem;
        background: url(~@/assets/images/iconReply.png) no-repeat left center;
        font-family: "DIN-Bold";
        background-size: 0.3rem;
        margin-left: 0.4rem;
        min-width: 0.8rem;
        &:first-child {
          margin-left: 0;
        }
      }
      .praise {
        padding-left: 0.48rem;
        background-image: url(~@/assets/images/iconPraise.png);
        background-size: 0.35rem;
        &.on {
          background-image: url(~@/assets/images/iconPraiseOn.png);
        }
      }
    }
  }
}
.hotJobs {
  position: relative;
  margin-bottom: 0.5rem;
  .moreBtn {
    position: absolute;
    right: 0;
    top: 0;
    height: 0.5rem;
    line-height: 0.5rem;
    padding-right: 0.2rem;
    font-size: 0.22rem;
    color: #5b5d6a;
    background: url(~@/assets/images/iconMore.png) no-repeat right center;
    background-size: 0.1rem;
  }
  .li {
    padding: 0.3rem 0.2rem 0.2rem;
    margin-bottom: 0.3rem;
    background: #fff;
    border-radius: 0.2rem;
    box-shadow: 0 0 0.24rem rgba(0, 0, 0, 0.04);
    .dt {
      position: relative;
      padding-bottom: 0.3rem;
    }
    h4 {
      font-size: 0.28rem;
      color: #3c3f50;
      line-height: 0.36rem;
      font-weight: 600;
    }
    p {
      font-size: 0.24rem;
      color: #5b5d6a;
      line-height: 0.36rem;
      margin-bottom: 0.2rem;
    }
    .time {
      position: absolute;
      right: 0;
      bottom: 0.3rem;
      font-size: 0.2rem;
      color: #999;
      line-height: 0.34rem;
    }
    .dd {
      border-top: 0.01rem solid #eee;
      display: flex;
      justify-content: space-between;
      padding-top: 0.24rem;
      .le {
        display: flex;
        align-items: center;
        .via {
          width: 0.58rem;
          height: 0.58rem;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 0.16rem;
        }
      }
      .contactBtn {
        height: 0.58rem;
        line-height: 0.58rem;
        font-size: 0.22rem;
        border-radius: 0.58rem;
        color: #fff;
        padding: 0 0.2rem 0 0.6rem;
        background: #007df8 url(~@/assets/images/iconConsult.png) no-repeat
          0.2rem center;
        background-size: 0.3rem;
      }
    }
  }
}
.videoList {
  position: relative;
  .moreBtn {
    position: absolute;
    right: 0;
    top: 0;
    height: 0.5rem;
    line-height: 0.5rem;
    padding-right: 0.2rem;
    font-size: 0.22rem;
    color: #5b5d6a;
    background: url(~@/assets/images/iconMore.png) no-repeat right center;
    background-size: 0.1rem;
  }
  .li {
    margin-bottom: 0.3rem;
    overflow: hidden;
    border-radius: 0.2rem;
    background: #fff;
    .dt {
      position: relative;
      min-height: 3rem;
      background: #d8d9db;
      .btn {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 0.7rem;
        height: 0.7rem;
        margin: -0.35rem 0 0 -0.35rem;
        border-radius: 50%;
        background: url(~@/assets/images/iconPlay.png) no-repeat center;
        background-size: contain;
        box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.1);
      }
    }
    .dd {
      height: 0.9rem;
      padding: 0.2rem;
      h4 {
        font-size: 0.28rem;
        color: #43465a;
        line-height: 0.5rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
.videoHint{
  .cnt{
    padding: .5rem;
    text-align: center;
    .til{
      font-size: .3rem;
      line-height: .46rem;
      color: #43465a;
    }
    .p{
      font-size: .28rem;
      line-height: .4rem;
      color: #aaa;
      margin-top: .4rem;
    }
  }
}
</style>

